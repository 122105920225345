@charset "UTF-8";
$font-min: "Noto Serif JP", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "BIZUDMincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font-time: "Times New Roman", "Georgia", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font-shiop: "Shippori Mincho", "Times New Roman", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "BIZUDMincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

$font-go:"游ゴシック体", "Yu Gothic", "游ゴシック", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;

$font-nobel: nobel, "nobel", "Roboto", "Helvetica Neue", Arial, "Lato", "Noto Sans JP", "游ゴシック体", "Yu Gothic", "游ゴシック", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;

$font-biz: "BIZ UDGothic";

@mixin ff_corm($weight: 600) {
  font-family: "Cormorant Infant", "游ゴシック体", "Yu Gothic", "游ゴシック", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
  font-weight: $weight;
	text-transform: uppercase;
}

@mixin ff_go($weight: 500) {
  font-family: $font-go;
}
@mixin ff_min($weight: 400) {
  font-family: $font-min;
  font-weight: $weight;
}

@mixin ff_shiop() {
  font-family: $font-shiop;
}

@mixin ff_biz() {
  font-family: $font-biz;
}

$trans_05: all cubic-bezier(0.15, 0.89, 0.36, 0.98) 0.5s;
