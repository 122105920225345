body{
	overflow-y: scroll;
}
.bl_headH1 {
  background-color: $blue;
  text-align: center;
  font-size: 1.3rem;
  color: $white;
	@include mq(md){
		font-size: 1.2rem;
	}
  &_heading {
    font-weight: 500;
    padding: 0.5em;
		@include mq(md){
			padding: 0.5em 0;
		}
  }
}
.ly_head {
  position: sticky;
  top: 0;
	left: 0;
	right: 0;
  z-index: 50;
  background-color: $white;
  &_inner {
    padding: 18px 40px;
		@include mq(md){
			padding: 4%;
		}
  }
}
.bl_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
	&_logo{
		@include mq(md){
			width: 50%;
		}
	}
  &_util {
		@include mq(md){
			width: 35%;
		}
    &_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
			align-items: stretch;
			@include mq(md){
				grid-template-columns: repeat(3, 1fr);
			}
			&_item{
				display: flex;
				align-items: center;
				justify-content: center;
				align-content: center;
				flex-wrap: wrap;
			}
			&_link{
				padding: 7px 15px;
				display: inline-block;
				@include mq(md){
					padding: 0;
				}
			}
    }
  }
}
