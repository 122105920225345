.ly_inner{
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 10px;
	display: block;
	@include mq(md){
		padding: 0 4vw;
	}
}
.ly_cont{
	padding: $margin_cont 0;
	&_btn{
		text-align: center;
		font-size: 1.6rem;
		margin-top: 2em;
		@include ff_corm;
		letter-spacing: 0.08em;
		@include mq(md){
			.el_bdBtn ,.el_arrowBtn {
				width: 92%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
.ly_main{
	color: $black;
	@include ff_go;
	img{
		max-width: 100%;
		height: auto;
	}
}