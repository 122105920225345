:root {
  --outer-height: 100vh;

  --main-gutter: 48px;
  --cont-gutter: 48px;
  @include mq(md) {
    --main-gutter: 12vw;
    --cont-gutter: 12vw;
  }
  /* モノクロ */
  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --color-black: #231815;
  --color-black-rgb: 35, 24, 21;
  --color-gray: #dfdfdf;
  --color-gray-rgb: 223, 223, 223;
  --color-gray-bg: #f9f9f9;
  --color-gray-dark: #8a8a8a;
  --color-gray-ab: #ababab;

  /* 赤 */
  --color-red: #e76a76;
  --color-red-rgb: 231, 106, 118;

  /* 黄色 */
  --color-yellow: #fcdc4a;
  --color-yellow-rgb: 252, 220, 74;

  /* 橙 */
  --color-orange: #ef8153;
  --color-orange-rgb: 239, 129, 83;

  /* 桃 */
  --color-pink: #f4c4d9;
  --color-pink-rgb: 244, 196, 217;

  /* 紫 */
  --color-purple: #d1b1d0;
  --color-purple-rgb: 209, 177, 208;

  /* 緑 */
  --color-green: #8da53c;
  --color-green-rgb: 141, 165, 60;
  --color-green-light: #a3c762;

  /* 青 */
  --color-blue: #89bee9;
  --color-blue-rgb: 137, 190, 233;
  --color-blue-dark: #738ea7;
  --color-blue-bg: #8aceee;

  /* 茶色 */
  --color-brown: #c5a46d;
  --color-brown-rgb: 197, 164, 109;

  /* ベージュ */
  --color-baige: #f5b99b;
  --color-baige-rgb: 245, 185, 155;

  /* 金 */
  --color-gold: #d9c480;
  --color-gold-rgb: 217, 196, 128;
}
