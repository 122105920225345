.el_bgc{
	background-size: cover;
	background-position: center;
	&__blk{
		background-color: #484848;
	}
	&__gry{
		background-color: #b3b3b3;
	}
	&__wht{
		background-color: $white;
		border: 1px solid $gray;
	}
	&__brn{
		background-color: $brown;
	}
	&__beg{
		background-color: $baige;
	}
	&__grn{
		background-color: $green;
		&-l{
			background-color: $green-l;
		}
	}
	&__blu{
		background-color: $blue-bg;
		&-d{
			background-color: $blue-d;
		}
	}
	&__ppl{
		background-color: $purple;
	}
	&__pnk{
		background-color: $pink;
	}
	&__red{
		background-color: $red;
	}
	&__orn{
		background-color: $orange;
	}
	&__yel{
		background-color: $yellow;
	}
	&__colorful{
		background-image: url(#{$common}bg_colorful.png);
	}
}