@use "sass:math";
@function get_vw($size, $viewport:1366) {
	$rate: math.div(100, $viewport);
	@return $rate * $size * 1vw;
}
@function get_sp_vw($size, $viewport:430) {
	$rate: math.div(100, $viewport);
	@return $rate * $size * 1vw;
}

//フォント用のmixin
@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}
@mixin fz_sp($font_size:10){
  font-size: $font_size * 0.1rem;
  font-size: get_sp_vw($font_size);
}

//フォント用のmixin
@mixin lh($line_height,$font_size){
  line-height:  math.div($line_height, $font_size);
}

@mixin pos100(){
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}


$margin: var(--main-gutter);
$margin_cont: var(--cont-gutter);
$vh100: var(--outer-height);

//line-height 打消し
$base-line-height:2;

@mixin lhCrop($line-height:$base-line-height, $capital-letter: 1) {
	&::before {
		content: '';
		display: block;
		height: 0;
		width: 0;
		margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
	}

	&::after {
		content: '';
		display: block;
		height: 0;
		width: 0;
		margin-bottom: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
	}
}