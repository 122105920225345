body.is_fixed{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.ly_gnav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overscroll-behavior: none;
  overflow: hidden;
  touch-action: none;
  @include ff_go;
	display: none;
  &_bg {
    background-color: rgba($black-rgb, 0.6);
    @include pos100;
    z-index: 5;
    overflow: hidden;
    overscroll-behavior: contain;
  }
  &_inner {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 10;
    overscroll-behavior-y: contain;
		@include mq(md){
			left: 0;
		}
  }
}
.bl_gnav {
  &_btn {
    display: inline-block;
    appearance: none;
    background-color: transparent;
    border: 0 none;
  }
  &_open {
    position: relative;
    width: 18px;
    height: 12px;
    span {
      display: block;
      height: 1px;
      background-color: $black;
      width: 100%;
      position: absolute;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        bottom: 50%;
        transform: translateY(50%);
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
  }
  &_head,
  &_body,
  &_foot {
    padding: 28px 40px;
		@include mq(md){
			padding: 4vw;
		}
  }
  &_head {
    background-color: $black;
  }
  &_body,
  &_foot {
    background-color: $white;
  }
	&_body{
		@include mq(md){
			padding: 0;
		}
	}
	&_close{
		margin-left: auto;
		display: block;
		margin-bottom: 10px;
	}
  &_lv2heading {
    font-size: 2.8rem;
    @include ff_corm;
    letter-spacing: 0.03em;
    margin-bottom: 0.5em;
    padding-bottom: 0.25em;
    border-bottom: 1px solid $gray;
    position: relative;
    &:nth-of-type(n + 2) {
      margin-top: 1.5em;
			@include mq(md){
				margin-top: 0;
			}
    }
    small {
      font-size: 0.428em;
      vertical-align: 0.3em;
      margin-left: 1em;
    }
		@include mq(md){
			padding: 2vw 4vw;
			margin-bottom: 0;
			&::before,&::after{
				content: "";
				display: block;
				position: absolute;
				bottom: 50%;
				right: 4vw;
				transform: translateX(50%);
				width: 0.5em;
				height: 1px;
				background-color: $black;
				transition: all ease 0.5s;
			}
			&::after{
				transform: translateX(50%) rotate(90deg);
			}
			&.is_show{
				&::after{
					transform: translateX(50%) rotate(0deg);
					opacity: 0;
				}
			}
		}
  }
  &_lv3heading {
    font-size: 2.2rem;
    @include ff_corm;
    letter-spacing: 0.03em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
		@include mq(md){
			margin-top: 1em;
			font-size: 1.8rem;
		}
  }

  &_column {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
		@include mq(md){
			display: none;
			padding-left: 4vw;
			padding-right: 4vw;
		}
  }
  &_item {
    &__13 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &__14 {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    &__24 {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  &_list {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    column-gap: 20px;
    row-gap: 10px;
		@include mq(md){
			grid-template-rows: auto;
			grid-auto-flow: row;
			grid-template-columns: repeat(1, 1fr);
			margin-bottom: 4vw;
		}
    &__row5 {
      grid-template-rows: repeat(5, 1fr);
			@include mq(md){
				grid-template-rows: auto;
				grid-auto-flow: row;
				grid-template-columns: repeat(1, 1fr);
			}
    }
    &_item {
      border: solid 1px $black;
      font-size: 1.4rem;
      line-height: 1;
			@include mq(md){
				font-size: 1.3rem;
			}
    }
    &_link {
      display: block;
      padding: 1.2em 1em;
    }
    &__price {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: row;
      grid-template-rows: auto;
    }
  }
  &_colors {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 16px;
    a {
      display: block;
      aspect-ratio: 1/1;
      border-radius: 5px;
    }
  }
  &_btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 48px;
		@include mq(md){
			padding: 4vw 8vw;
			grid-template-columns: repeat(1, 1fr);
			margin-top: 6vw;
			row-gap: 4vw;
			font-size: 1.4rem;
		}
  }
  &_foot {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
		line-height: 1;
		@include mq(md){
			display: block;
			text-align: center;
		}
		li{
			border-right: 1px solid $gray;
			border-left: 1px solid $gray;
			margin-left: -1px;
			@include mq(md){
				margin-left: 0;
				border: none;
				margin-top: 2em;
			}
		}
  }


	&_ex{
		@include mq(md){
			padding: 8vw 8vw 160px;
			background-color: $white;
			font-size: 1.4rem;
		}
	}
}
