.el_lv2heading{
	@include ff_corm;
	font-size: 6.4rem;
	text-align: center;
	margin-bottom: 1em;
	line-height: 1.2;
	color: $black;
	@include lhCrop(1.2);
	@include mq(md){
		font-size: 4.8rem;
		margin-bottom: 0.8em;
	}
}