.el_bdBtn{
	border-radius: 20em;
	display: block;
	text-align: center;
	padding: 1.5em 1em 1.4em;
	background-color: $blue;
	color: $white;
	appearance: none;
	border: none;
	letter-spacing: 0.07em;
	line-height: 1;
	&__inline{
		display: inline-block;
		min-width: 250px;
	}
	&__whtLine{
		border: 1px solid $white;
		background-color: transparent;
	}
	&__bleLine{
		border: 1px solid $blue;
		background-color: transparent;
		color: $blue;
	}
}
.el_arrowBtn{
	border-radius: 20em;
	display: block;
	text-align: center;
	padding: 1.5em 1em 1.4em;
	background-color: $blue;
	color: $white;
	appearance: none;
	border: none;
	letter-spacing: 0.07em;
	line-height: 1;
	&__inline{
		display: inline-block;
		min-width: 250px;
	}
	&::after{
		content: "";
		display: inline-block;
		margin-left: 1em;
		width: 1em;
		height: 1em;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		background-image: url(#{$common}arrow_btn_wht.svg);
		vertical-align: -0.2em;
	}
	&__whtLine{
		border: 1px solid $white;
		background-color: transparent;
	}
}