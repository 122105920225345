::selection {
	//background-color: rgba($brown,0);
}

.pc_none,.sp {
	display: none;

	@include mq(md) {
		display: block;
	}
}

.sp_none,.pc {
	@include mq(md) {
		display: none;
	}
}

.pc_contents {
	@include mq(md) {
		display: none;
	}
}

.sp_contents {
	display: none;

	@include mq(md) {
		display: block;
	}
}

.no_link {
	display: none;
}


.el_preload {
	display: none;
}