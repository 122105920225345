.bl_categories{
	@include mq(md){
		border-top: 1px solid $black;
		padding-top: calc($margin + 8vw);
	}
}
.bl_categoryList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  text-align: center;
	@include mq(md){
		grid-template-columns: repeat(2, 1fr);
		gap: 4vw;
		padding-left: 6px;
	}
  &_en,
  &_ja {
    display: block;
    line-height: 1.5;
		@include mq(md){
			left: -6px;
			position: relative;
			line-height: 1.2;
		}
  }
  &_en {
    @include ff_corm(400);
    letter-spacing: 0.05em;
  }
  &_ja {
    letter-spacing: 0.05em;
    font-size: 1.5rem;
    margin-top: 0.25em;
		@include mq(md){
			font-size: 1.3rem;
		}
  }
  &_item {
    position: relative;
		@include mq(md){
			
			
		}
    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      background-color: $black;
      width: 1px;
    }
    &::before {
      top: 10px;
			right: 10px;
			bottom: 20px;
			z-index: 1;
			@include mq(md){
				top: 6px;
				right: 6px;
				bottom: 12px;
			}
    }
    &::after {
      bottom: 10px;
			left: -10px;
			top: 20px;
			@include mq(md){
				left: -6px;
				bottom: 6px;
				top: 12px;
			}
    }
  }
  &_link {
    display: block;
    height: 100%;
    width: 100%;
    background-color: $bg_gray;
    font-size: 2.4rem;
    padding: 1em 0;
    position: relative;
		@include mq(md){
			font-size: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			align-content: center;
			flex-direction: row;
			aspect-ratio: 1/1;
		}
    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      background-color: $black;
      height: 1px;
      left: -10px;
      right: 10px;
			@include mq(md){
				left: -6px;
				right: 6px;
			}
    }
    &::before {
      top: 10px;
			@include mq(md){
				top: 6px;
			}
    }
    &::after {
      bottom: 10px;
			@include mq(md){
				bottom: 6px;
			}
    }
  }
}
