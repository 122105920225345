.bl_contact {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(#{$common}bg_contact.jpg);
  padding: 64px 0;
  margin-top: 120px;
  @include ff_go;
  color: $white;
	@include mq(md){
		padding: 8vw 0 $margin;
		margin-top: $margin;
	}
  &_heading {
    text-align: center;
  }
  &_head {
    padding-bottom: 34px;
    margin-bottom: 50px;
    border-bottom: 1px solid $white;
		@include mq(md){
			position: relative;
			border-bottom: none;
			margin-bottom: $margin;
			padding-bottom: $margin;
			&::after{
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: -4vw;
				right: -4vw;
				height: 1px;
				background-color: $white;
			}
		}
    &_txt {
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      line-height: 2em;
      text-align: center;
      margin-top: 2.5em;
			@include mq(md){
				text-align: left;
				font-size: 1.2rem;
			}
    }
  }
  &_body {
		text-align: center;
    &_heading {
      @include ff_corm(500);
      font-size: 3.2rem;
      letter-spacing: 0.05em;
			@include mq(md){
				font-size: 2.4rem;
			}
    }
		&_txt{
			font-size: 1.2rem;
		}
  }
  &_tel {
    @include ff_corm(500);
    font-size: 4rem;
    letter-spacing: 0.05em;
		margin: 0.25em 0;
		line-height: 1;
		@include mq(md){
			font-size: 3.2rem;
		}
		a{
			color: $white;
		}
  }
	&_btns{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 32px;
		font-size: 1.4rem;
		margin-top: 2.2em;
		@include mq(md){
			gap: 0.8em;
		}
		.el_bdBtn{
			display: inline-block;
			padding-left: 8em;
			padding-right: 8em;
			@include mq(md){
				display: block;
			}
		}
	}
}
