.bl_serchform {
	font-size: 1.6rem;
	position: relative;
	border-bottom: 1px solid $white;
	display: grid;
	grid-template-columns: 1em 1fr;
	&_input[type="text"]{
		background-color: transparent;
		padding: 1em;
		border: none 0;
		@include ff_corm;
		color: $white;
		width: 100%;
		&:focus{
			outline: 0;
			border: none;
		}
	}
	&_btn{
		appearance: none;
		background-color: transparent;
		border: none 0;
	}
}