@charset "UTF-8";
//変数

/* モノクロ */
$black: var(--color-black);
$black-rgb: var(--color-black-rgb);
$white: var(--color-white);
$white-rgb: var(--color-white-rgb);
$gray: var(--color-gray);
$gray-rgb: var(--color-gray-rgb);
$bg_gray: var(--color-gray-bg);
$d_gray: var(--color-gray-dark);
$garay_ab: var(--color-gray-ab);

/* 暖色 */
$red: var(--color-red);
$red-rgb: var(--color-red-rgb);
$orange: var(--color-orange);
$orange-rgb: var(--color-orange-rgb);
$yellow: var(--color-yellow);
$yellow-rgb: var(--color-yellow-rgb);
$pink: var(--color-pink);
$pink-rgb: var(--color-pink-rgb);
$purple: var(--color-purple);
$purple-rgb: var(--color-purple-rgb);

/* 寒色 */
$green: var(--color-green);
$green-rgb: var(--color-green-rgb);
$green-l: var(--color-green-light);
$blue: var(--color-blue);
$blue-rgb: var(--color-blue-rgb);
$blue-d: var(--color-blue-dark);
$blue-bg: var(--color-blue-bg);

/* 茶色 */
$brown: var(--color-brown);
$brown-rgb: var(--color-brown-rgb);
$baige: var(--color-baige);
$baige-rgb: var(--color-baige-rgb);
$gold: var(--color-gold);
$gold-rgb: var(--color-gold-rgb);

//ブルー系
$d_blue: #242e3d;
$blue_35: #3589be;
$blue_eb: #ebf7fc;
$blue_1f: #1f4880;
$blue_1d: #1da7f0;
$blue_50: #5099aa;
$blue_0b: #0b2655;

//グリーン系
$green_a7: #a7c941;
$green_67: #67a35a;

//オレンジ系
$or_df: #df5513;

//ピンク系
$l_pink: #ffdbff;
$d_pink: #df628a;

//赤系
$red_ce: #ce7457;
//ブラウン系

//黄色系
$l_yellow: #fff9b1;

//モノクロ
$black_53: #535356;
$black_4d: #4d4d4d;
$l_black: #6a6a6d;
$l_l_black: #808080;
$gray: #e8e8e5;
$l_gray: #d9d9d9;

$border_gray: #dadada;

$gray_c6: #c6c6c6;
$gray_e0: #e0e0e0;
$gray_ad: #adadad;
$gray_f4: #f4f4f4;
$gray_93: #939393;
$gray_f1: #f1f1f3;
$gray_f2: #f2f2f2;
$gray_f3: #f3f3f3;
$gray_a9: #a9a9a9;
$gray_ef: #efefef;
$gray_80: #808080;
$gray_f0: #f0f0f0;
$gray_df: #dfdfdc;
$gray_ea: #eaeaea;
$gray_e9: #e9e9e9;

$be_g: #bebebe;
$thin_g: #f4f4f4;
$l_g: #d6d6d6;

$pink_gra: linear-gradient(60deg, $l_pink 0%, $pink 25%);
$pink_gra_d: linear-gradient(180deg, $l_pink 0%, $pink 100%);
$pink_gra_r: linear-gradient(180deg, $l_pink 0%, $pink 100%);

$shadow: drop-shadow(7px 7px 15px rgba($black-rgb, 0.3));
