.ly_foot {
  padding-top: $margin;
  padding-bottom: 24px;
	@include mq(md){
		padding-bottom: 120px;
	}
}
.bl_foot {
  @include mq(md) {
  }
  &_heading {
    @include ff_corm;
    font-size: 1.8rem;
    letter-spacing: 0.03em;
    margin-bottom: 0.5em;
    padding-bottom: 0.25em;
    border-bottom: 1px solid $gray;
    color: $black;
		position: relative;
    @include mq(md) {
      margin-left: -4vw;
      margin-right: -4vw;
      padding: 2vw 4vw;
      margin-bottom: 0;
      font-size: 1.6rem;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 50%;
        right: 4vw;
        transform: translateX(50%);
        width: 0.5em;
        height: 1px;
        background-color: $black;
        transition: all ease 0.5s;
      }
      &::after {
        transform: translateX(50%) rotate(90deg);
      }
      &.is_show {
        &::after {
          transform: translateX(50%) rotate(0deg);
          opacity: 0;
        }
      }
    }
    &:nth-of-type(n + 2) {
      margin-top: 1.5em;
      @include mq(md) {
        margin-top: 0;
      }
    }
  }
  &_column {
    display: grid;
    grid-template-columns: 1fr 2fr 0.9fr;
    column-gap: 60px;
    @include mq(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &_list {
    font-size: 1.4rem;
    @include mq(md) {
      font-size: 1.3rem;
      margin-bottom: 1em;
      & + & {
        margin-bottom: 0;
      }
    }
    li {
    }
    a {
      padding: 0.5em 0;
      display: block;
      line-height: 1.5;
      @include mq(md) {
        padding: 1em 0;
      }
    }
    &_wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      @include mq(md) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &_sns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: $margin;
    column-gap: 16px;
		@include mq(md){
			margin-top: 8vw;
		}
  }

  &_btns {
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 5;
    @include mq(md) {
      bottom: 65px;
      right: 4vw;
      &_link {
        display: block;
        width: 80px;
      }
    }
  }
}
#ch-shadow-root-wrapper div[data-ch-testid="launcher"]{
	@include mq(md) {
		bottom: 145px;
		right: 4vw;
	}
}
.el_copy {
  text-align: center;
  font-size: 1.2rem;
  @include ff_corm;
  margin-top: $margin;
	@include mq(md){
		margin-top: 8vw;
	}
}

.js_footNav{
	@include mq(md){
		display: none;
	}
}
.bl_fooNav{
	@include mq(md){
		display: block;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		display: grid;
		grid-template-columns: repeat(4,1fr);
		z-index: 10000;
		background-color: $black;
		font-size: 1.1rem;
		color: $white;
		height: 60px;
		&_link{
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			align-content: center;
			color: $white;
		}
		&_txt{
			display: block;
			text-align: center;
			line-height: 1;
			color: $white;
		}
	}
}