//  レスポンシブ
$breakpoints: (
	xxls: "only screen and (min-width: 1401px) and (max-width: 1800px)",
	xxl: "only screen and (max-width: 1800px)",
	xl: "only screen and (max-width: 1400px)",
	lg: "only screen and (max-width: 1200px)",
	omd: "only screen and (min-width: 861px)",
	md: "only screen and (max-width: 860px)",
	sd: "only screen and (max-width: 600px)",
);

@mixin mq($breakpoint) {
	@media #{map-get($breakpoints,$breakpoint)} {
		@content;
	}
}